import {css, html, TemplateResult} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import {msg, str} from '@lit/localize';
import {LitElement} from 'lit';
import {button} from "../styles/button";

@customElement('maintenance-display')
export class MaintenanceDisplay extends LitElement {
    static override styles = [
        button,
        css`
            .thrilltech-maintenance {
                padding: 24px 12px 24px 12px;
            }

            .thrilltech-maintenance-icon {
                text-align: center;
            }

            .thrilltech-maintenance-title {
                margin: 8px 0 8px 0;
                font-size: 14px;
                font-weight: 700;
                line-height: 19.6px;
                text-align: center;
            }
            .thrilltech-maintenance-description {
                margin: 0px 0 24px 0;
                font-size: 12px;
                font-weight: 400;
                line-height: 16.8px;
                text-align: center;
            }
            .buttons {
                width: 100%;
                display: flex;
                gap: 16px;
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }

            .buttons .primary,
            .buttons .secondary {
                min-width: 207px;
                height: 38px;
            }
        `
    ];

    @property({type: Boolean, attribute: "maintenance"})
    maintenance = false;

    @property({type: Boolean, attribute: 'player-opted-in'})
    playerOptedIn = false;

    override connectedCallback(): void {
        super.connectedCallback();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
    }

    override render(): TemplateResult<1> {
        return html`
            <div class="thrilltech-maintenance">
                <div class="thrilltech-maintenance-icon">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M45.9119 39.0559L22.6856 15.8295C21.6025 14.7465 21.0483 13.2434 21.1631 11.717C21.3867 8.82952 20.395 5.86619 18.19 3.65536C16.1872 1.65647 13.57 0.666748 10.9508 0.666748C9.965 0.666748 8.97722 0.806748 8.02444 1.08675L14.1883 7.25064C14.8572 10.3404 10.3792 14.869 7.2525 14.1865L1.09055 8.02258C0.80861 8.97925 0.666666 9.96702 0.666666 10.9567C0.666666 13.5759 1.65833 16.1892 3.65722 18.192C5.86417 20.4009 8.82944 21.3906 11.7189 21.167C13.2414 21.0504 14.7464 21.6065 15.8275 22.6895L39.0558 45.914C39.9658 46.824 41.1986 47.3334 42.4839 47.3334C45.1439 47.3334 47.3333 45.179 47.3333 42.484C47.3333 41.2434 46.8589 40.0029 45.9119 39.0559ZM42.4722 44.4167C41.3969 44.4167 40.5278 43.5456 40.5278 42.4723C40.5278 41.399 41.3969 40.5279 42.4722 40.5279C43.5475 40.5279 44.4167 41.399 44.4167 42.4723C44.4167 43.5456 43.5475 44.4167 42.4722 44.4167ZM27.0431 14.6881L37.4011 4.34758C38.5367 3.21008 40.0222 2.64036 41.5097 2.64036C42.9953 2.64036 44.4828 3.20814 45.6183 4.34758C46.7636 5.48897 47.3333 6.98619 47.3333 8.47953C47.3333 9.96508 46.7675 11.4506 45.6358 12.5823L35.2797 22.9229L27.0431 14.6881ZM20.1792 32.5381L10.0156 42.6201C9.57417 43.0673 9.19305 43.569 8.89944 44.1251L8.31417 45.2529L4.23083 47.3334L2.61111 45.7117L4.60417 41.539L5.73194 40.9517C6.28805 40.6601 6.82278 40.312 7.26805 39.8667L17.4317 29.7848L20.1792 32.5381Z" fill="#606F76"/>
                </svg>
                </div>
                <div class="thrilltech-maintenance-title">${msg(str`PlayJumbo`)}</div>
                <div class="thrilltech-maintenance-description">${this.getMaintenanceDescription()}</div>
                <div class="buttons">
                    <button class="primary"
                        href="javascript:void(0)"
                        @click="${this.maintenanceClose}">
                        ${msg(str`Confirm`)}
                    </button>
                </div>
            </div>
        `;
    }

    private getMaintenanceDescription() {
        if (this.maintenance && this.playerOptedIn) {
            return msg(str`Sorry, PlayJumbo is currently under maintenance. You are still opted in but all your contributions are temporarily paused.`);
        } else if (this.maintenance) {
            return msg(str`Sorry, PlayJumbo is currently under maintenance.`);
        } else if (!this.maintenance && this.playerOptedIn) {
            return msg(str`PlayJumbo is no longer in maintenance! Your contributions are no longer paused.`);
        } else {
            return msg(str`PlayJumbo is now available for opt-in!`);
        }
    }

    private maintenanceClose(): void {
        this.dispatchEvent(new CustomEvent('maintenance-close', {bubbles: true}));
    }
}

if (!customElements.get('maintenance-display')) {
    customElements.define('maintenance-display', MaintenanceDisplay);
}