import {css, html, TemplateResult} from 'lit';
import {property, customElement, state} from 'lit/decorators.js';
import {msg, str} from '@lit/localize';
import {LitElement} from 'lit';
import {classMap} from "lit/directives/class-map.js";
import {button} from "../styles/button";

const POSTBACK_PATH = "/fragment/gamefilter/ajax/thrillpot/opt-in";

@customElement('opt-in-form')
export class OptInForm extends LitElement {
    static override styles = [
        button,
        css`
            :host {
                text-align: center;
                font-size: 14px;
                font-family: var(--thrilltech-font-family);
            }
            
            .opt-in-form {
                max-width: 100%;
                margin: 0 auto;
                padding: 24px;
            }
            
            .opt-in-form-title {
                line-height: var(--thrilltech-opt-in-form-title-line-height);
            }

            .bet-options {
                padding: 24px;
            }
            
            .bet-option {
                height: 34px;
                width: 64px;
                font-weight: 400;
                background-color: var(--thrilltech-opt-in-form-bet-option-bg-color);
                border: var(--thrilltech-opt-in-form-bet-option-border);
                border-radius: var(--thrilltech-opt-in-form-bet-optin-border-radius);
                color: var(--thrilltech-opt-in-form-bet-option-text-color);
            }

            .bet-option--active {
                background-color: var(--thrilltech-opt-in-form-bet-option-active-bg-color);
                border-color: var(--thrilltech-opt-in-form-bet-option-active-border-color);
                color: var(--thrilltech-opt-in-form-bet-option-active-text-color);
            }
                
            .buttons {
                width: 100%;
                display: flex;
                gap: 16px;
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }

            .buttons .primary,
            .buttons .secondary {
                min-width: 207px;
                height: 38px;
            }

            @media screen and (min-width: 1024px) { 
                .opt-in-form-title { 
                    font-size: 16px;
                    line-height: 22.4px;
                }

                .opt-in-form {
                    padding: 40px 30px;
                    margin: auto;
                    width: auto;
                }

                .bet-option {
                    font-size: 14px;
                }
            }
        `
    ];

    @property({type: Array, attribute: 'bet-options'})
    betOptions:number[] = [];

    @property({type: Number, attribute: 'current-bet-option'})
    currentBetOption = 0;

    @property({type: Boolean, attribute: 'player-opted-in'})
    playerOptedIn = false;

    @property({type: String, attribute: 'thrillpot-source'})
    thrillpotSource = '';

    @property({type: Boolean, attribute: 'user-in-game-page'})
    userInGamePage = false;

    @property({type: Number, attribute: 'site-id'})
    siteId = 1;

    @property({type: String, attribute: 'thrillpot-currency'})
    thrillpotCurrency = 'USD';

    @state()
    betOption?:number;

    private abortController:AbortController|null = null;

    override connectedCallback(): void {
        super.connectedCallback();
        this.bindEvents();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
        this.removeEvents();
    }

    private bindEvents() {
        addEventListener('message', this.postMessageHandler.bind(this));
    }

    private removeEvents() {
        removeEventListener('message', this.postMessageHandler.bind(this));
    }

    private postMessageHandler(event: MessageEvent) {
        if (window.location.origin !== event.origin) {
            return;
        }
    }

    override render(): TemplateResult<1> {
        return html`
            <div class="opt-in-form">
                <div class="opt-in-form-title">
                    ${this.getPromptMessage()}
                </div>
                <div data-testid="thrilltech-bet-options" class="bet-options">
                    ${this.getBetOptionsHtml()}
                </div>
                <div class="buttons">
                    <button data-testid="thrilltech-optIn-button"
                            class="primary"
                            @click="${this.optIn}"
                            ?disabled="${this.disabledButtonCss()}">
                        ${this.playerOptedIn ? msg(str`Confirm`) : (this.siteId === 1 ? msg(str`Join`) : msg(str`Join`, {id: 'yu-join'}))}
                    </button>
                    ${this.getSecondaryButton()}
                    ${this.getPromptTextLink()}
                </div>
            </div>
        `;
    }

    private getBetOptionsHtml(): TemplateResult<1>[] {
        return this.betOptions.map(option => {
            const formatted: string = new Intl.NumberFormat(
                this.thrillpotCurrency === 'USD' ? 'en' : 'ja',
                { style: 'currency', currency: this.thrillpotCurrency }
            ).format(option);

            return html`
                <button class="bet-option ${this.isOptionSelectedCss(option)}"
                        @click="${() => this.betOption = option}"
                >
                    ${formatted}
                </button>`
        });
    }

    private disabledButtonCss() {
        return (!this.playerOptedIn && !this.isOptionSelected());
    }

    private getPromptMessage(): TemplateResult<1> {
        return html`
            ${this.playerOptedIn ? 
                (this.siteId === 1 ? msg(str`Edit your stake below by selecting a different amount to contribute`) : msg(str`Edit your stake below by selecting a different amount to contribute`, {id: 'yu-edit-stake-prompt'})):
                msg(str`Select the amount you want to contribute from one of the options below:`)
            }
        `
    }

    private getPromptTextLink(): TemplateResult<1> {
        if (this.playerOptedIn) {
            return html`
                <a data-testid="thrilltech-optOut-link" class="link" href="javascript:void(0)"
                    @click="${this.optOut}">
                    ${this.siteId === 1 ? msg(str`Opt out`) : msg(str`Opt out`, {id: 'yu-opt-out'})}
                </a>
            `;
        }
        return html`
            <a class="link" href="javascript:void(0)"
                @click="${this.showMoreInfo}">
                ${msg(str`More info`)}
            </a>
        `;
    }

    private getSecondaryButton() {
        if (this.playerOptedIn) {
            return html`
                <button class="secondary"
                    href="javascript:void(0)"
                    @click="${this.closeDrawer}">
                    ${msg(str`Cancel`)}
                </button>
            `;
        } else {
            return;
        }
    }

    private closeDrawer(): void {
        this.dispatchEvent(new CustomEvent('cancel-opt-in', {bubbles: true}));
        this.betOption = this.currentBetOption;
    }

    private isOptionSelectedCss(option:number) {
        return classMap({
            'bet-option--active': (this.betOption || this.currentBetOption) === option
        });
    }

    private isOptionSelected(): boolean {
        return (this.betOption != null);
    }

    private async optIn(): Promise<void> {
        if(this.playerOptedIn && !this.betOption) {
            this.closeDrawer();
            return;
        }

        try {
            this.abortController = new AbortController();
            const result = await fetch(
                `${POSTBACK_PATH}/${this.thrillpotSource}/${this.betOption}/${this.siteId}/${this.thrillpotCurrency}`,
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        Authorization: `Bearer ${top?.JWToken}`,
                    },
                    signal: this.abortController.signal,
                }
            );

            if (!result.ok) {
                this.reportOptInToGTM({
                    event: this.playerOptedIn ? 'thrilltech_edit_stake' : 'thrilltech_opt_in',
                    pot: this.thrillpotSource,
                    stake: this.betOption,
                    location: this.userInGamePage ? 'gamepage' : 'lobby',
                    outcome: 'failure',
                    site: this.siteId
                });

                return this.handleError(msg(str`Opt-in Unsuccessful`), msg(str`We were unable to complete your request to join PlayJumbo. Please try again.`));
            }

            this.reportOptInToGTM({
                event: this.playerOptedIn ? 'thrilltech_edit_stake' : 'thrilltech_opt_in',
                pot: this.thrillpotSource,
                stake: this.betOption,
                location: this.userInGamePage ? 'gamepage' : 'lobby',
                outcome: 'success',
                site: this.siteId
            });
            window.postMessage({ action: 'THRILLTECH_PLAYER_OPTED_IN', source_id: this.thrillpotSource});
            this.dispatchEvent(
                new CustomEvent(
                    this.playerOptedIn ? 'successEditStake' : 'successOptIn', 
                    {
                        detail: {
                            newBetOption: this.betOption ? this.betOption : this.currentBetOption,
                        },
                        bubbles: true
                    }
                )
            );
        } catch (error:any) {
            const title = msg(str`Unexpected error`);
            let message = msg(str`Something went wrong! Please try again!`);
            if (error instanceof Error) {
                message = error.message
            }

            this.handleError(title, message);
        } finally {
            this.abortController = null;
        }
    }

    private async optOut(): Promise<void> {
        try {
            this.abortController = new AbortController();
            const result = await fetch(
                `${POSTBACK_PATH}/${this.thrillpotSource}/0/${this.siteId}/${this.thrillpotCurrency}`,
                {
                    method: 'DELETE',
                    mode: 'cors',
                    headers: {
                        Authorization: `Bearer ${top?.JWToken}`,
                    },
                    signal: this.abortController.signal,
                }
            );

            if (!result.ok) {
                this.reportOptInToGTM({
                    event: 'thrilltech_opt_out',
                    pot: this.thrillpotSource,
                    stake: this.currentBetOption,
                    location: this.userInGamePage ? 'gamepage' : 'lobby',
                    outcome: 'failure',
                    site: this.siteId
                });

                return this.handleError(msg(str`Opt-out Unsuccessful`), msg(str`We were unable to complete your request to opt out of PlayJumbo. Your bets will still contribute to the prize pool until you have opted out. Please try again.`));
            }

            this.reportOptInToGTM({
                event: 'thrilltech_opt_out',
                pot: this.thrillpotSource,
                stake: this.currentBetOption,
                location: this.userInGamePage ? 'gamepage' : 'lobby',
                outcome: 'success',
                site: this.siteId
            });
            window.postMessage({ action: 'THRILLTECH_PLAYER_OPTED_OUT', source_id: this.thrillpotSource });
            this.dispatchEvent(new CustomEvent('successOptOut', {bubbles: true}));
        } catch (error:any) {
            const title = msg(str`Unexpected error`);
            let message = msg(str`Something went wrong! Please try again!`);
            if (error instanceof Error) {
                message = error.message
            }

            this.handleError(title, message);
        } finally {
            this.abortController = null;
        }
    }

    private showMoreInfo(): void {
        this.dispatchEvent(new CustomEvent('tnc', {bubbles: true}));
    }

    private handleError(title:string, message:string, retry = true, tryReload = false): void {
        this.dispatchEvent(
            new CustomEvent(
                'error',
                {
                    detail: {
                        errorTitle: title, 
                        errorMessage: message,
                        retry,
                        tryReload,
                    },
                    bubbles: true
                }
            )
        );
    }

    private reportOptInToGTM(payload:Object): void {
        if (typeof window.dataLayer?.push !== 'function') {
            return;
        }

        payload.uiprovider = document.cookie.match(
            '(^|;)\\s*Embedded_Referrer\\s*=\\s*([^;]+)'
        )?.pop() || 'ENJOY';

        window.dataLayer.push(payload);
    }
}

if (!customElements.get('opt-in-form')) {
    customElements.define('opt-in-form', OptInForm);
}
