import {css, html, LitElement, nothing, TemplateResult} from 'lit';
import {property, customElement} from 'lit/decorators.js';

@customElement('drop-down')
export class DropDown extends LitElement {
    static override styles = [
        css`
            .title-wrapper {
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                line-height: 22px;
                align-items: center;
                font-weight: 700;
                background: var(--thrilltech-drop-down-bg-color);
            }

            .drop-down-wrapper {
                margin: 16px 0 16px 0;
            }

            .drop-down-alternatives-wrapper {
                color: var(--thrilltech-drop-down-text-color);
                padding: 16px;
                border: var(--thrilltech-drop-down-border);
                border-radius: var(--thrilltech-drop-down-border-radius);
                margin-top: 8px;
                background: var(--thrilltech-drop-down-bg-color);
            }

            .text-answer {
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                margin: 16px 0 16px 0;
                padding: 8px 16px 0 16px;
                background: var(--thrilltech-drop-down-bg-color);
            }

            .buttonIcon {
                display: flex;
                align-items: center;
                background-repeat: no-repeat;
                height: 24px;
                flex-basis: 24px;
                flex-grow: 0;
                flex-shrink: 0;
            }

            .activeButton {
                background-image: var(--thrilltech-drop-down-active-button);
            }

            .inActiveButton {
                background-image: var(--thrilltech-drop-down-in-active-button);
            }

            .touch-target {
                text-decoration: none;
                color: var(--thrilltech-drop-down-text-color);
            }
        `
    ]
    @property({type: String, attribute: 'title-question'})
    titleQuestion = '';

    @property({type: String, attribute: 'text-answer'})
    textAnswer= '';

    @property()
    open = false;

    override render(): TemplateResult<1> {
        return html`
            <a href="javascript:void(0)" class="touch-target image" @click="${{handleEvent: () => this.open = !this.open}}">
                <div class="drop-down-alternatives-wrapper">
                    <div class="title-wrapper">
                        <div class="title">
                            ${this.titleQuestion}
                        </div>
                        <div class="buttonIcon ${this.open ? 'inActiveButton' : 'activeButton'}"></div>
                    </div>
                    ${this.open ? html`  <div class="text-answer"> ${this.textAnswer} </div> `: nothing }
                </div>
            </a>
        `;
    }
}

if (!customElements.get('drop-down')) {
    customElements.define('drop-down', DropDown);
}
