import {css, html, LitElement, nothing, TemplateResult} from 'lit';
import {customElement,property} from 'lit/decorators.js';
import {button} from "../styles/button";
import {msg, str} from '@lit/localize';

if (typeof require !== 'undefined') {
    require("./drop-down");
}
@customElement('terms-and-conditions')
export class TermsAndConditions extends LitElement {
    static override styles = [
        button,
        css`
            .navbar-wrapper {
                display: flex;
                padding: 4px;
                border-radius: 5px;
                background-color: var(--thrilltech-terms-navbar-wrapper-bg-color);
                border: var(--thrilltech-terms-navbar-wrapper-border);
                margin-bottom: 20px;
                width: 90%;
            }

            .navbar-background-wrapper {
                width: 100%;
                position: fixed;
                top: 44px;
                left: 0;
                background: var(--thrilltech-terms-navbar-background-wrapper-bg);
                display: flex;
                justify-content: space-around;
                box-shadow: var(--thrilltech-terms-navbar-background-wrapper-box-shadow);
            }

            .terms-and-conditions-wrapper .terms-and-conditions-text-wrapper {
                border-radius: 4px;
                border: var(--thrilltech-terms-wrapper-border);
                padding: 16px;
                height: 430px;
                font-size: 12px;
                background-color: var(--thrilltech-terms-wrapper-bg-color);
            }
            .terms-and-conditions-wrapper {
                text-align: center;
                padding: 40px 12px 24px 12px;
                min-height: 560px;
            }

            .terms-and-conditions-text-wrapper,
            .faq {
                text-align: left;
                margin: 45px 0px 20px;
            }
            .terms-and-conditions-text {
                font-size: 10px;
                font-weight: 400;
                line-height: 14px;
                padding-right: 8px;
                height: 100%;
                overflow: auto;
            }
            .terms-and-conditions-text::-webkit-scrollbar {
                background-color: var(--thrilltech-terms-text-scrollbar-bg-color);
                border: var(--thrilltech-terms-text-scrollbar-border);
                border-radius: var(--thrilltech-terms-scrollbar-border-radius);
                background-clip: padding-box;
            }
            .terms-and-conditions-text::-webkit-scrollbar-thumb {
                background-color: var(--thrilltech-terms-text-scrollbar-thumb-bg-color);
                border: var(--thrilltech-terms-text-scrollbar-border);
                border-radius: var(--thrilltech-terms-scrollbar-border-radius);
                background-clip: padding-box;
            }
            .terms-and-conditions-text li > a {
                font-size: 10px;
            }

            .not-chosen-navbar {
                background: var(--thrilltech-terms-not-chosen-navbar-bg-color);
                width: 50%;
                color: var(--thrilltech-terms-not-chosen-navbar-text-color);
            }
            .chosen-navbar {
                background: var(--thrilltech-terms-chosen-navbar-bg-color);
                width: 50%;
                color: var(--thrilltech-terms-chosen-navbar-text-color);
                border-radius: var(--thrilltech-terms-chosen-navbar-border-radius);
            }
            .navbar {
                padding: 8px;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-around;
                height: 15px;
                line-height: 16.8px;
                font-size: 14px;
                cursor: pointer;
            }

            .thrilltech-drawer-content {
                padding: 24px 8px 24px 8px;
            }

            .terms-and-conditions-links {
                display: flex;
                gap: 15px;
                flex-direction: column;
                align-items: center;
            }

            .terms-and-conditions-links > p {
                font-size: 14px;
                margin: 0;
            }

            @media screen and (min-width: 1024px) {
                .terms-and-conditions-wrapper {
                    padding: 40px 24px 15px;
                    min-height: 550px;
                }

                .terms-and-conditions-wrapper .terms-and-conditions-text-wrapper {
                    height: 420px;
                }        

                .navbar-background-wrapper {
                    top: 65px;
                }
            }
        `
    ]
    @property()
    faqOrTermsAndConditions = true;

    @property({type: Number, attribute: 'site-id'})
    siteId =  1;

    private getQuestionsAndAnswers(): string[][] {
        return [
            [msg(str`What is PlayJumbo?`), msg(str`PlayJumbo is a side bet feature that allows players to potentially benefit from three different prize pools while they play slot games or live casino titles (with the exception of certain games).`)],
            [msg(str`With which games can I participate in PlayJumbo?`), msg(str`All slot and live casino games that have the PlayJumbo menubar shown at the bottom of the screen are eligible`)],
            [msg(str`How can I opt in to PlayJumbo?`), msg(str`You can join the respective PlayJumbo jackpot by opting in on the Slots or Live Casino lobby page, or while in your chosen eligible game.`)],
            [msg(str`What can I win?`), this.siteId === 1 ? msg(str`Prize money will be awarded. There are three categories: VeraJumbo, Jumbo, and Lucky Hour, with different prize amounts for each prize.`) : msg(str`Prize money will be awarded. There are three categories: YuugaJumbo, Jumbo, and Lucky Hour, with different prize amounts for each prize.`)],
            [msg(str`How will winners be notified?`), msg(str`Winners will be notified while playing each slot or live casino or game screen. The main winners of each category will be notified of their winnings instantly. Additionally, if you are a Lucky 9 Prize winner and are not logged in at the time you win, you will receive a pop-up message notification the next time you log in.`)],
            [msg(str`Is there a connection between winning the game and winning PlayJumbo?`), msg(str`Gameplay wins and PlayJumbo wins are not related. Winnings from gameplay and prizes awarded from winning PlayJumbo are separate and will appear in separate transactions in your account's transaction history.`)]
        ];
    }

    private printDropDowns(): TemplateResult<1>[] {
         return this.getQuestionsAndAnswers().map(function (value) {
              return html` <drop-down
                            title-question="${value[0]}"
                            text-answer="${value[1]}"
                         ></drop-down>` });
    }

    private getFaq(): TemplateResult<1> {
        return html`<div class="faq">
            ${this.printDropDowns()}
            </div>`
    }

    private getTermsAndConditions(): TemplateResult<1> {
        return html`<div class="terms-and-conditions-text-wrapper">
                    <div class="terms-and-conditions-text">
                        <h3>${msg(str`PlayJumbo Terms and Conditions`)}</h3>
                        <h4>${msg(str`General description of PlayJumbo`)}</h4>
                        <ol>
                            <li>${msg(str`PlayJumbo is a betting feature that allows players to contribute in cash to potentially benefit from three different prize pools while they play. Whenever you place a bet on any slot games and/or live casino games (the "Eligible Games”) by opting in as described below, you can set aside an additional bet (the "Contribution") to contribute towards the PlayJumbo prize.`)}</li>
                        </ol>
                        <h4>${msg(str`How to participate in PlayJumbo`)}</h4>
                        <ol start="2">
                            <li>${msg(str`PlayJumbo is available on Eligible Games only.`)}</li>
                            <li>${msg(str`PlayJumbo Contributions are divided into two separate prize pools; one for slot games and the other for live casino games. If you wish to participate in either or both prize pools, you will need to opt in separately for each one.`)}</li>
                            <li>${msg(str`To participate in PlayJumbo and set your Contribution amount, you must first opt in via the in-game display or the game lobby page.`)}</li>
                            <li>${msg(str`Once you have opted in, Contributions towards PlayJumbo will be made from your cash balance to the corresponding PlayJumbo prize pool every time you play an Eligible Game.`)}</li>
                            <li>${msg(str`If you are logged into your Account and have a cash balance, your chosen wager and Contributions will be deducted from your Account whenever you place a bet on an Eligible Game.`)}</li>
                            <li>${msg(str`If your cash balance is insufficient to cover both the gameplay and the Contribution, no further Contributions will be made until your cash balance is topped up and Contributions can begin again.`)}</li>
                            <li>${msg(str`You can opt out from PlayJumbo and stop your Contributions towards the PlayJumbo prizes at any time. However, for the avoidance of doubt, you will only be eligible to win PlayJumbo prizes while you are opted in. PlayJumbo prizes can only be triggered while you are logged in and placing wagers on Eligible Games.`)}</li>
                        </ol>
                        <h4>${msg(str`PlayJumbo Mechanics`)}</h4>
                        <ol start="9">
                            <li>${this.siteId === 209 ? msg(str`Each prize pool contains three prizes: i) Lucky Hour; ii) Jumbo; and iii) Yuuga Jumbo. Each prize pool increases progressively with each player Contribution. Prize values can be found on the in-game display and on the respective game lobby page.`) : msg(str`Each prize pool contains three prizes: i) Lucky Hour; ii) Jumbo; and iii) Vera Jumbo. Each prize pool increases progressively with each player Contribution. Prize values can be found on the in-game display and on the respective game lobby page.`)}</li>
                            <li>${msg(str`PlayJumbo prize pools are completely funded by player Contributions.`)}</li>
                            <li>${this.siteId === 209 ? msg(str`PlayJumbo prizes are divided as follows: i) Lucky Hour is a 100% share of the prize pool that is won by one individual winner; and ii) Jumbo and Yuuga Jumbo prizes are divided into a) a 50% share of the prize pool that is won by one individual winner (the “Main Prize”); and b) the other 50% share of the prize pool that is shared between nine other players selected on the basis of their historical Contributions (the “Community Prize”).`) : msg(str`PlayJumbo prizes are divided as follows: i) Lucky Hour is a 100% share of the prize pool that is won by one individual winner; and ii) Jumbo and Vera Jumbo prizes are divided into a) a 50% share of the prize pool that is won by one individual winner (the “Main Prize”); and b) the other 50% share of the prize pool that is shared between nine other players selected on the basis of their historical Contributions (the “Community Prize”).`)}</li>
                            <li>${msg(str`PlayJumbo prizes can only be triggered while you are logged in and placing wagers on Eligible Games. PlayJumbo prizes will not be triggered on unfunded rounds.`)}</li>
                            <li>${msg(str`The Main Prize winner will not be eligible to receive a Community Prize.`)}</li>
                            <li>${msg(str`All players who are opted-in will be notified whenever a PlayJumbo prize is won.`)}</li>
                            <li>${msg(str`A notification will be sent to Community Prize winners who are not logged in upon next log-in.`)}</li>
                            <li>${msg(str`PlayJumbo prizes will be automatically transferred to the winner’s cash balance.`)}</li>
                            <li>${msg(str`Winnings from PlayJumbo are subject to our verification. Our decisions are final, and no correspondence will be entered into in relation to any prize-related complaints.`)}</li>
                            <li>${msg(str`If communication between us and the game server fails, or if an Eligible Game becomes unavailable, or there is any form of software malfunction, there will be no Contributions or PlayJumbo prizes won. The prize amount will remain the same until communication is resumed, the Eligible Game becomes available or the relevant software malfunction is resolved.`)}</li>
                            <li>${msg(str`We may terminate (or decommission) PlayJumbo at our discretion at any time (including before a prize is won). In any such event we will do our utmost to refund any outstanding Contributions as soon as reasonably practicable.`)}</li>
                            <li>${msg(str`We reserve the right to modify or terminate PlayJumbo and change the Eligible Games at any time without prior notice.`)}</li>
                            <li>${msg(html`<a class="link" target="_blank" href="${this.getTermsAndConditionsLink() + 'terms-and-conditions'}">General terms and conditions</a> and <a class="link" target="_blank" href="${this.getTermsAndConditionsLink() + 'promotional-terms-and-conditions'}">General Promo terms and conditions</a> apply together with these PlayJumbo terms and conditions. In the event of a conflict, the PlayJumbo terms and conditions prevail.`)}</li>
                            <li>${msg(html`For information about how we handle your personal data, please refer to our <a class="link" target="_blank" href="${this.getTermsAndConditionsLink() + 'privacy-policy'}">Privacy Policy</a>.`)}</li>
                        </ol>
                    </div>
                </div>`
    }

    private getNavbar(): TemplateResult<1> {
        return html`
            <div class="navbar-background-wrapper">
                <div class="navbar-wrapper">
                        <div @click="${{handleEvent: () => this.faqOrTermsAndConditions =  true }}"
                            class="navbar ${this.faqOrTermsAndConditions ? `chosen-navbar` : `not-chosen-navbar`}">
                            <span>${msg(str`FAQ`)}</span></div>
                        <div
                            @click="${{handleEvent: () => this.faqOrTermsAndConditions = false}}"
                            class="navbar ${!this.faqOrTermsAndConditions ? `chosen-navbar` : `not-chosen-navbar`}">
                            <span>${msg(str`Terms & Conditions`)}</span></div>
                </div>
            </div>
        `
    }

    override render(): TemplateResult<1> {
        return html`
            <div class="terms-and-conditions-wrapper">
                ${this.getNavbar()}
                <div> ${this.faqOrTermsAndConditions ?
                    this.getFaq() :
                    this.getTermsAndConditions()} 
                </div>
                <div class="terms-and-conditions-links">
                    ${this.faqOrTermsAndConditions ?
                            html`<p>${
                                this.siteId === 1 ?
                                        msg(html`To read more go to the <a class="link" target="_blank" href="${this.getTermsAndConditionsLink() + 'howtoplayjumbo'}">PlayJumbo Guide</a>`) :
                                        msg(html`To read more go to the <a class="link" target="_blank" href="${this.getTermsAndConditionsLink() + 'howtoplayjumbo'}">PlayJumbo Guide</a>`, {id: 'yu-read-more-guide'})
                            }</p>` :
                            nothing}
                    <a class="link" href="javascript:void(0)"
                       @click="${this.exitTermsAndConditions}">
                        ${msg(str`Go back`)}
                    </a>
                </div>
            </div>
        `;
    }

    private getTermsAndConditionsLink(): string {
        if(this.siteId === 209) {
            return "https://yuugado.com/about/";
        } 

        return "https://play.verajohn.com/about/";
    }

    private exitTermsAndConditions(): void {
        this.dispatchEvent(new CustomEvent('exit-tnc', {bubbles: true}));
    }
}

if (!customElements.get('terms-and-conditions')) {
    customElements.define('terms-and-conditions', TermsAndConditions);
}
