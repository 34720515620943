import {css} from "lit";

export const button = css`
    button {
        border-radius: 4px;
        border: 0;
        cursor: pointer;
        text-decoration: none;
        padding: 7px;
        height: 38px;
        font-size: 14px;
        font-weight: var(--thrilltech-button-font-weight);
        line-height: 19.6px;
        border-radius: var(--thrilltech-button-radius);
        font-family: var(--thrilltech-font-family);
        border-radius: var(--thrilltech-button-border-radius);
        border: var(--thrilltech-button-border);
    }
    
    button.primary {
        background: var(--thrilltech-button-primary-bg-color);
        color: var(--thrilltech-button-primary-text-color);
    }

    button.secondary {
        background: var(--thrilltech-button-secondary-bg-color);
        color: var(--thrilltech-button-secondary-text-color);
    }

    button.primary:disabled,
    button.primary[disabled] {
        opacity: 0.5;
    }

    .link {
        font-size: 14px;
        color: var(--thrilltech-button-link-text-color);
    }

    @media screen and (min-width: 1024px) { 
        button {
            font-size: 16px;
        }
    }
`;