import {css, html, LitElement, TemplateResult} from 'lit';
import {property, customElement} from "lit/decorators.js";
import {msg, str} from '@lit/localize';
import {button} from "../styles/button";

@customElement('opt-in-status')
export class OptInStatus extends LitElement {
    static override styles = [
        button,
        css`
            .opt-in-status {
                margin: auto;
            }
            .opt-in-status-wrapper {
                padding: 24px;
                margin: 0 24px 0 24px;
                height: 100%;
                align-items: center;
                font-size: 20px;
                text-align: center;
                justify-content: center;
                font-family: var(--thrilltech-font-family);
            }

            .opt-in-status-title {
                font-size: var(--opt-in-status-title-font-size);
                line-height: var(--thrilltech-opt-in-status-title-line-height);
            }

            .opt-in-status-description {
                font-size: var(--opt-in-status-description-font-size);
                line-height: var(--thrilltech-opt-in-status-description-line-height);
            }

            .opt-in-status-title {
                font-weight: 700;
                margin-top: 16px;
            }

            .opt-in-status-description  {
                margin-top: 8px;
                font-weight: 400;
            }

            .successImage {
                margin: auto;
                width: 42px; 
                height: 42px;
                background: var(--thrilltech-opt-in-status-sucess-image);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }

            .buttons {
                width: 100%;
                display: flex;
                gap: 16px;
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }

            .buttons .primary,
            .buttons .secondary {
                min-width: 207px;
                padding: 0 24px 0 24px;
                height: 38px;
            }

            .buttons .primary {
                margin-top: 24px;
            }

            @media screen and (min-width: 1024px) {
                .opt-in-status-wrapper {
                    padding: 30px 24px 40px 24px;
                }

                .opt-in-status-title,
                .opt-in-status-description {
                    font-size: var(--opt-in-status-font-size-big);
                    line-height: var(--thrilltech-opt-in-status-line-height-big);
                }
            }
        `
    ]

    @property({type: Boolean, attribute: 'user-in-game-page'})
    userInGamePage = false;

    @property({type: String, attribute: 'status'})
    status = "OPT_IN";

    @property({type: Number, attribute: 'site-id'})
    siteId =  1;

    buttonObject = {
        primaryButtonText: '',
        secondaryButtonText: '',
        link: ''
    };

    constructor() {
        super();
    }

    override connectedCallback(): void {
        super.connectedCallback();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
    }

    private getTranslations(): void {
        if (this.status=="OPT_IN") {
            if(this.userInGamePage) {
                this.buttonObject.primaryButtonText = msg(str`Continue playing`);
            } else {
                this.buttonObject.primaryButtonText = (this.siteId === 1 ? msg(str`Go to games`) : msg(str`Go to games`, {id: 'yu-go-to-games'}));
            }
        } else if (this.status=="EDIT") {
            this.buttonObject.primaryButtonText = msg(str`Continue playing`);
        } else {
            this.buttonObject.primaryButtonText = msg(str`Join again`);
            this.buttonObject.secondaryButtonText = msg(str`Close`);
        }

    }

    private showButtonOrGames() {
        this.getTranslations();
        if (this.status=="OPT_IN" || this.status=="EDIT") {
            return html`
            <div class="buttons">
                <a href="javascript:void(0)"
                    @click="${this.completeOptIn}">
                    <button class="primary">${this.buttonObject.primaryButtonText}</button>
                </a>
            </div>`;  
        } else {
            return html`
                <div class="buttons">
                    <button class="primary"
                        href="javascript:void(0)"
                        @click="${this.reloadDrawer}">
                        ${this.buttonObject.primaryButtonText}
                    </button>
                    <button class="secondary"
                        href="javascript:void(0)"
                        @click="${this.closeDrawer}">
                        ${this.buttonObject.secondaryButtonText}
                    </button>
                </div>
            `;
        }
    }

    private reloadDrawer(): void {
        this.dispatchEvent(new CustomEvent('reload-drawer-handler', {bubbles: true}));
    }

    private closeDrawer(): void {
        this.dispatchEvent(new CustomEvent('cancel-opt-in', {bubbles: true}));
    }

    private completeOptIn() {
        this.dispatchEvent(new CustomEvent('opt-in-complete-close', {bubbles: true}));
    }

    private getStatusMessage() {
        if (this.status=="EDIT") {
            return html`
                <div class="successImage"></div>
                <div class="opt-in-status-title">${ this.siteId === 1 ? msg(str`You've changed your stake`) : msg(str`You've changed your stake`, {id: 'yu-you-changed-stake'})}</div>
            `;
        } else if (this.status=="OPT_IN") {
            return html`
                <div class="successImage"></div>
                <div class="opt-in-status-title">${ this.siteId === 1 ? msg(str`You've opted in!`) : msg(str`You've opted in!`, {id: 'yu-you-opted-in'}) }</div>
                <div class="opt-in-status-description">${ this.siteId === 1 ? msg(str`You can keep track of the prizes as they are about to drop while you play`) : msg(str`You can keep track of the prizes as they are about to drop while you play`, {id: 'yu-keep-track-of-prizes'}) }</div>
            `;
        }
        return html`
            <div class="opt-in-status-description" style="margin-top:0">${ this.siteId === 1 ? msg(str`You have now opted out of PlayJumbo`) : msg(str`You have now opted out of PlayJumbo`, {id: 'yu-opt-out-prompt'}) }</div>
        `;
    }

    override render(): TemplateResult<1> {
            return html`
                <div class="opt-in-status">
                    <div class="opt-in-status-wrapper">
                        ${ this.getStatusMessage() }
                        ${ this.showButtonOrGames() }
                    </div>
                </div>
            `;
        }
    }

if (!customElements.get('opt-in-status')) {
    customElements.define('opt-in-status', OptInStatus);
}
