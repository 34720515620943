import {css, html, LitElement, nothing, TemplateResult} from 'lit';
import {customElement, property} from "lit/decorators.js";
import {msg, str} from '@lit/localize';
import {button} from "../styles/button";
import {ThrilltechConfig} from "../interfaces/ThrillTechConfig";
import {classMap} from "lit/directives/class-map.js";

const enum PROGRESS {
    IN_PROGRESS,
    TNC,
    COMPLETE,
    THRILLPOTS
}

@customElement('thrilltech-tickers')
export class ThrilltechTickers extends LitElement {
    constructor() {
        super();
    }

    static override styles = [
        css`
            .thrillpot-container {
                padding: 42px 24px 16px 24px;
                align-items: center;
                position: relative;
                display: grid;
                border-radius: 8px;
                row-gap: 16px;
            }

            span.thrilltech-must-drop {
                font-family: var(--thrilltech-font-family);
                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: center;
            }

            span.thrilltech-title {
                font-family: var(--thrilltech-font-family);
                font-size: 12px;
                font-weight: 700;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: center;
            }

            .thrilltech-divider {
                display: none;
            }

            .buttons {
                display: flex;
                gap: 16px;
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }

            .buttons .primary,
            .buttons .secondary {
                min-width: 207px;
                height: 38px;
            }

            .thrillpot-item {
                position: relative;
                align-items: center;
                display: flex;
                flex-direction: column;
            }

            .thrillpot-container.thrillpot-container-content-cards .thrillpot-item:nth-of-type(1) {
                grid-area: 1 / 1 / 1 / 3;
                padding-top: 2px;
            }

            .thrillpot-container.thrillpot-container-content-cards .thrillpot-item:nth-of-type(2) {
                grid-area: 2 / 1 / 2 / 2;
            }

            .thrillpot-container.thrillpot-container-content-cards .thrillpot-item:nth-of-type(3) {
                grid-area: 2 / 2 / 2 / 3;
            }

            .thrillpot-container.thrillpot-container-content-cards  > .buttons {
                grid-area: 3 / 1 / 3 / 3;
            }

            .thrillpot-container.thrillpot-container-game-play .thrillpot-item:nth-of-type(2) {
                grid-area: 2 / 1 / 2 / 3;
            }

            .thrillpot-container.thrillpot-container-game-play .thrillpot-item:nth-of-type(3) {
                grid-area: 3 / 1 / 3 / 2;
            }

            .thrillpot-container.thrillpot-container-game-play .thrillpot-item:nth-of-type(4) {
                grid-area: 3 / 2 / 3 / 3;
            }

            .thrillpot-container.thrillpot-container-game-play > .buttons {
                grid-area: 4 / 1 / 4 / 3;
            }

            .thrillpot-container.thrillpot-container-game-play { 
                padding: 24px;
            }

            .thrillpot-container.thrillpot-container-game-play .thrillpot-join-text {
                font-size: 14px;
                line-height: 19.6px;
                text-align: center;
                font-family: var(--thrilltech-font-family);
                grid-area: 1 / 1 / 1 / 3;
            }

            .thrillpot-clock-image{
                width: 180px;
                height: 64px;
                background-image: var(--thrilltech-image);
            }

            @media only screen and (max-width: 765px) {
                :host .thrillpot-container {
                    flex-direction: column;
                }
                .thrillpot-image {
                    top: -30px;
                    left: calc(50% - 90px);
                    position: absolute;
                    z-index: 1;
                }
            }

            @media only screen and (min-width: 766px) {
                .buttons .primary,
                .buttons .secondary {
                    height: 44px;
                }

                .thrillpot-container.thrillpot-container-content-cards {
                    display: flex;
                }

                .thrillpot-item:nth-of-type(1) {
                    padding-top: 0;
                }

                .thrillpot-join-text {
                    font-size: 16px;
                }

                .thrillpot-container-content-cards > .buttons .primary,
                .thrillpot-container-content-cards > .buttons .secondary {
                    width: 135px;
                    min-width: 0;
                }

                .thrilltech-divider {
                    display: block;
                    height: 73px;
                    border-left: var(--thrilltech-tickers-devider);
                } 

                .thrilltech-divider:last-of-type {
                      display: none;
                }

                :host .thrillpot-container {
                    justify-content: space-between;
                }
            }

            @media only screen and (min-width: 766px) and (max-width: 1023px) {
                .thrillpot-image {
                    top: -40px;
                    left: calc(50% - 90px);
                    position: absolute;
                }

                .thrillpot-opted-in .thrillpot-image {
                    top: -50px;
                }

                .thrillpot-container.thrillpot-container-content-cards {
                    padding: 30px 24px 24px;
                }

                .thrillpot-container.thrillpot-container-game-play {
                    padding: 24px 40px;
                    justify-content: space-around;
                }
            }

            @media screen and (min-width: 1024px) {
                .thrillpot-container {
                    padding: 20px 24px;
                }

                .thrillpot-image {
                    padding-top: 5px;
                }

                .thrillpot-container.thrillpot-container-game-play {
                    padding: 40px 60px;
                    justify-content: space-between;
                    gap: 24px;
                }
            }`,
            button
    ]

    @property({type: Object, attribute: 'thrillpot'})
    thrillpot: ThrilltechConfig = {
        sourceId: '',
        participation: {
            contributionValue: 0,
        },
        pots: [],
    }

    @property({type: Boolean, attribute: 'player-opted-in'})
    playerOptedIn = false;

    @property({type: Boolean, attribute: 'user-in-game-page'})
    userInGamePage = false;

    @property({type: Number, attribute: 'site-id'})
    siteId = 1;

    @property({type: String, attribute: 'thrillpot-currency'})
    thrillpotCurrency = 'USD';

    private printTiers() {
        const printOrder = ['mega', 'major', 'minor'];

        return printOrder.map((tier) => {
            return html`
                <div class="thrillpot-item">
                    <thrillpot-ticker
                            ?user-in-game-page="${this.userInGamePage}"
                            thrillpot-source="${this.thrillpot.sourceId}"
                            thrillpot-info="${JSON.stringify(this.thrillpot.pots.find(({ type }) => type === tier))}"
                            site-id="${ this.siteId }"
                            thrillpot-currency="${ this.thrillpotCurrency }"
                    > </thrillpot-ticker>
                </div>
                ${ !this.userInGamePage ? html`<span class="thrilltech-divider"></span>` : `` } `
        });
    }

    private printClockSvg() {
        return html`<div class="thrillpot-clock-image"></div>`
    }

    private returnJoinText() {
        return html`<div class="thrillpot-join-text"> 
            ${ this.playerOptedIn ? 
                    msg(str`You are participating in PlayJumbo`) :
                    (this.siteId === 1 ? msg(str`Be in with a chance of winning today!`) : msg(str`Be in with a chance of winning today!`, {id: 'yu-join-prompt'}))
            }
        </div>`
    }
    override render(): TemplateResult<1> {
        return html`
            <div class="thrillpot-container  ${this.getContainerClasses()}""> 
                ${ this.userInGamePage ? this.returnJoinText() : nothing }
                ${ this.printTiers() }
                ${!this.userInGamePage ?
                    html`
                        <div class="thrillpot-image">
                            ${ this.printClockSvg() }
                        </div>
                    `
                    : nothing }
               
                <div class="buttons">
                    ${this.getSecondaryButton()}
                    ${this.getPromptTextLink()}
                </div>
            </div>
        `
    }

    override connectedCallback(): void {
        super.connectedCallback();
        this.bindEvents();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
        this.removeEvents();
    }

    private bindEvents() {
        addEventListener('message', this.postMessageHandler.bind(this));
    }

    private removeEvents() {
        removeEventListener('message', this.postMessageHandler.bind(this));
    }

    private postMessageHandler(event: MessageEvent) {
        if (window.location.origin !== event.origin) {
            return;
        }
        if(event.data.source_id?.toLowerCase() != this.thrillpot.sourceId?.toLowerCase()){
            return;
        }

        switch (event.data.action) {
            case 'THRILLTECH_PLAYER_OPTED_IN':
                this.playerOptedIn = true;
                break;
            case 'THRILLTECH_PLAYER_OPTED_OUT':
                this.playerOptedIn = false;
                break;
            default:
                break;
        }
    }

    private getPromptTextLink(): TemplateResult<1> {
        return html`
            <a class="link" href="javascript:void(0)"
                @click="${() => { this.openDrawer(PROGRESS.TNC)} }">
                ${msg(str`More info`)}
            </a>
        `;
    }

    private openDrawer(progress:number) {
        window.postMessage({ action: 'THRILLTECH_DRAWER', source_id: this.thrillpot.sourceId, open: true, process: progress });
    }

    private getSecondaryButton() {
        if (this.playerOptedIn) {
            return html`
                <button data-testid="thrilltech-${this.thrillpot.sourceId}-edit-button" class="secondary"
                        @click="${() => {this.openDrawer(PROGRESS.IN_PROGRESS)}}">
                        ${msg(str`Edit Stake`)}
                </button>
            `;
        } else {
            return html`
                <button data-testid="thrilltech-${this.thrillpot.sourceId}-join-button" class="primary"
                    @click="${() => { this.openDrawer(PROGRESS.IN_PROGRESS)}}">
                    ${this.siteId === 1 ? msg(str`Join`) : msg(str`Join`, {id: 'yu-join'})}
                </button>
            `;
        }
    }

    private getContainerClasses() {
        return classMap({
            'thrillpot-opted-in': this.playerOptedIn,
            'thrillpot-container-game-play': this.userInGamePage,
            'thrillpot-container-content-cards': !this.userInGamePage
        });
    }
}

if (!customElements.get('thrilltech-tickers')) {
    customElements.define('thrilltech-tickers', ThrilltechTickers);
}
