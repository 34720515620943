export const POST_MESSAGE_DRAWER = 'THRILLTECH_DRAWER';
export const POST_MESSAGE_THRILLPOT_UPDATE_CHANNEL_VERAJOHN = 'thrilltech_';
export const POST_MESSAGE_THRILLPOT_WINNING_CHANNEL_VERAJOHN = 'thrilltech-jackpot';

export const POST_MESSAGE_THRILLPOT_UPDATE_CHANNEL_YUUGADO = 'yuugado-thrilltech_';

export const POST_MESSAGE_THRILLPOT_WINNING_CHANNEL_YUUGADO = 'yuugado-thrilltech-jackpot';

export class ChannelClass {
    public static getUpdateChannel(site: Number, sourceId: string): string {
        if (site == 1) {
            return POST_MESSAGE_THRILLPOT_UPDATE_CHANNEL_VERAJOHN + sourceId.toLowerCase()
        } else {
            return POST_MESSAGE_THRILLPOT_UPDATE_CHANNEL_YUUGADO + sourceId.toLowerCase()
        }
    }

    public static getWinChannel(site: Number): string {
        if (site == 1) {
            return POST_MESSAGE_THRILLPOT_WINNING_CHANNEL_VERAJOHN
        } else {
            return POST_MESSAGE_THRILLPOT_WINNING_CHANNEL_YUUGADO
        }
    }
}
