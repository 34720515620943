import {css, html, LitElement, nothing, TemplateResult} from 'lit';
import {msg, str} from '@lit/localize';
import {property, customElement, state} from "lit/decorators.js";
import {ChannelClass} from "../constants/PostMessageConstants";

const enum PROGRESS {
    IN_PROGRESS,
    TNC,
    COMPLETE,
    THRILLPOTS
}
if (typeof require !== 'undefined') {
    require("./thrilltech-tickers");
}

@customElement('thrilltech-content-card')
export class ThrilltechContentCard extends LitElement {
    constructor() {
        super();
    }

    static override styles = [
        css`
            .thrillpot--content-card {
                position: relative;
            }

            .thrillpot-container {
                align-items: center;
                display: flex;
                flex-direction: column;
                position: relative;
                border-radius: var(--thrillpot-container-border-radius);
                background: white;
                border: var(--thrillpot-container-border-size) solid var(--thrillpot-container-border-color);
            }

            .thrillpot-maintenance {
                opacity: 0.2;
                pointer-events: none;
            }

            .thrillpot-maintenance-overlay {
                background-color: var(--thrillpot-maintenance-overlay-background);
                position: absolute;
                width: 75%;
                max-width: 350px;
                min-height: 110px;
                bottom: 25%;
                left: 0;
                right: 0;
                margin: 0 auto;
                text-align: center;
                border: 1px solid var(--thrillpot-maintenance-border); 
                border-radius: 16px;
                padding: 20px 16px;
                z-index: 2;
            }

            .thrillpot-maintenance-description {
                color: #7D3500;
                font-size: 14px;
                margin-top: 16px;
            }

            span.thrilltech-must-drop {
                font-family: Lucida Grande, serif;
                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: center;
            }

            span.thrilltech-title {
                font-family: Lucida Grande, serif;
                font-size: 12px;
                font-weight: 700;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: center;
            }

            .thrillpot-container-items{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 8px;
                padding-bottom: 16px;
            }

            .opted-in-status-mark {
                width: 16px;
                height: 17px;
                background-image: var(--thrilltech-opted-in-status-mark);
            }

            .thrillpot-container-items div:first-child {
                width: 100%;
                position: relative;
                align-items: center;
                display: flex;
                flex-direction: column;
            }

            .thrillpot-item {
                position: relative;
                align-items: center;
                display: flex;
                flex-direction: column;
            }

            .thrillpot-banner{
                background: var(--thrillpot-banner);
                height: 20px;
                position: absolute;
                width: 100%;
                border-radius: var(--thrillpot-banner-border-radius);
                display: flex;
                align-items: center;
            }
            .thrillpot-joined{
                background: var(--thrillpot-joined-background);
                height: 20px;
                align-items: center;
                gap: 2px;
                display: flex;
                font-size: 12px;
                position: absolute;
                padding: 2px 2px 2px 5px;
                border-radius: var(--thrillpot-joiend-border-radius);
                line-height: 20px;
                color: var(--thrillpot-joined-color);
            }

            @media only screen and (max-width: 765px) {
                :host .thrillpot-container{
                    flex-direction: column;
                    margin-top: 16px;
                }
                .thrillpot-joined{
                    right: 8px;
                    top: 28px;
                }
            }

            @media only screen and (min-width: 766px) {
                .thrillpot-maintenance-overlay {
                    bottom: 10%;
                }

                .thrillpot-banner {
                    position: relative;
                }

                .thrillpot-joined {
                    right: 8px;
                    top: 0;
                }

                .thrillpot-container{
                    margin: 0;
                }

                .thrillpot-joined {
                    padding: 0;
                }
            }

            @media only screen and (min-width: 1024px) {
                .thrillpot-maintenance-overlay {
                    bottom: 8%;
                }

                .thrillpot-joined{
                    right: 47%;
                    top: 0;
                }
            }

        `
    ]

    @property({type: Boolean, attribute: 'player-opted-in'})
    playerOptedIn = false;

    @property({type: JSON, attribute: 'thrillpot'})
    thrillpot = "";

    @property({type: Boolean, attribute: 'maintenance'})
    maintenance = false;

    @state()
    progress:PROGRESS = PROGRESS.THRILLPOTS;

    @state()
    isError = false;

    @property({type: String, attribute: 'thrillpot-source'})
    thrillpotSource = '';

    @property({type: String, attribute: 'thrillpot-currency'})
    thrillpotCurrency = 'USD';

    @property({type: Number, attribute: 'site-id'})
    siteId =  1;

    override connectedCallback(): void {
        super.connectedCallback();
        this.bindEvents();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
        this.removeEvents();
        this.messageSubscription();
    }

    private bindEvents() {
        addEventListener('message', this.postMessageHandler.bind(this));
    }

    private removeEvents() {
        removeEventListener('message', this.postMessageHandler.bind(this));
    }

    private messageSubscription(start = true) {
        window.postMessage({
            action: start ? 'subscribe' : 'unsubscribe',
            channel:  ChannelClass.getUpdateChannel(this.siteId, this.thrillpotSource)
        }, window.location.origin);

    }

    private postMessageHandler(event: MessageEvent) {
        if (window.location.origin !== event.origin) {
            return;
        }

        if (event.data.channel == ChannelClass.getUpdateChannel(this.siteId, this.thrillpotSource)) {
            let thrillpotData = event.data.message;

            if (typeof(thrillpotData) === 'string') {
                thrillpotData = JSON.parse(thrillpotData);
            }

            if (!thrillpotData) {
                return;
            }

            if (Object.hasOwn(thrillpotData, "maintenance")) {
                if (typeof thrillpotData.maintenance === 'boolean') {
                    this.maintenance = thrillpotData.maintenance;
                    console.log("Updated PlayJumbo maintenance mode to " + thrillpotData.maintenance);
                } else {
                    console.error("Invalid PlayJumbo maintenance mode type " + thrillpotData.maintenance);
                }
                return;
            }
        } else if (event.data.source_id?.toLowerCase() === this.thrillpotSource?.toLowerCase()) {
            switch (event.data.action) {
                case 'THRILLTECH_PLAYER_OPTED_IN':
                    this.playerOptedIn = true;
                    break;
                case 'THRILLTECH_PLAYER_OPTED_OUT':
                    this.playerOptedIn = false;
                    break;
                default:
                    break;
            }
        }
    }


    private printThrillPots() {
        return html`
            <thrilltech-tickers
                style="width:100%;"
                ?player-opted-in="${ this.playerOptedIn }"
                thrillpot=${ this.thrillpot }
                site-id="${ this.siteId }"
                thrillpot-currency="${ this.thrillpotCurrency }"
            ></thrilltech-tickers>
        `;
    }

    private printOptedInBanner() {
        return html`<div class="thrillpot-banner">
                        <div data-testid="thrilltech-${ this.thrillpotSource }-joined"
                            class="thrillpot-joined">
                            ${ msg(str`Joined`) }
                            <span class="opted-in-status-mark"></span>
                        </div>
                    </div>`;
    }

    private printMaintenanceOverlay() {
        return html`<div class="thrillpot-maintenance-overlay">
                        <div class="thrillpot-maintenance-icon">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M38.7817 32.905L18.8733 12.9967C17.945 12.0683 17.47 10.78 17.5683 9.47167C17.76 6.99667 16.91 4.45667 15.02 2.56167C13.3033 0.848333 11.06 0 8.815 0C7.97 0 7.12333 0.12 6.30667 0.36L11.59 5.64333C12.1633 8.29167 8.325 12.1733 5.645 11.5883L0.363333 6.305C0.121667 7.125 0 7.97167 0 8.82C0 11.065 0.85 13.305 2.56333 15.0217C4.455 16.915 6.99667 17.7633 9.47333 17.5717C10.7783 17.4717 12.0683 17.9483 12.995 18.8767L32.905 38.7833C33.685 39.5633 34.7417 40 35.8433 40C38.1233 40 40 38.1533 40 35.8433C40 34.78 39.5933 33.7167 38.7817 32.905ZM35.8333 37.5C34.9117 37.5 34.1667 36.7533 34.1667 35.8333C34.1667 34.9133 34.9117 34.1667 35.8333 34.1667C36.755 34.1667 37.5 34.9133 37.5 35.8333C37.5 36.7533 36.755 37.5 35.8333 37.5ZM22.6083 12.0183L31.4867 3.155C32.46 2.18 33.7333 1.69167 35.0083 1.69167C36.2817 1.69167 37.5567 2.17833 38.53 3.155C39.5117 4.13333 40 5.41667 40 6.69667C40 7.97 39.515 9.24333 38.545 10.2133L29.6683 19.0767L22.6083 12.0183ZM16.725 27.3183L8.01333 35.96C7.635 36.3433 7.30833 36.7733 7.05667 37.25L6.555 38.2167L3.055 40L1.66667 38.61L3.375 35.0333L4.34167 34.53C4.81833 34.28 5.27667 33.9817 5.65833 33.6L14.37 24.9583L16.725 27.3183Z" fill="#7D3500"></path>
                            </svg>
                        </div>
                        <div class="thrillpot-maintenance-description">
                            ${ this.getMaintenanceDescription() }
                        </div>
                    </div>`;
    }

    private getMaintenanceDescription() {
        if (this.playerOptedIn) {
            return msg(str`Sorry, PlayJumbo is currently under maintenance. You are still opted in but all your contributions are temporarily paused.`);
        } else {
            return msg(str`Sorry, PlayJumbo is currently under maintenance.`);
        }
    }

    override render(): TemplateResult<1> {
        return html`
            <div class="thrillpot--content-card">
                ${this.maintenance ? this.printMaintenanceOverlay() : nothing}
                <div class="thrillpot-container${this.maintenance ? ' thrillpot-maintenance' : ''}">
                    ${this.playerOptedIn ? this.printOptedInBanner() : nothing}
                    ${this.renderBody()}
                </div>
            </div>
            <thrilltech-drawer
                    thrillpot-config="${this.thrillpot}"
                    ?player-opted-in="${this.playerOptedIn}"
                    ?maintenance="${this.maintenance}"
                    site-id="${this.siteId}"
                    thrillpot-currency="${this.thrillpotCurrency}"
            ></thrilltech-drawer>`;
    }

    private renderBody() {
       return this.printThrillPots()
    }
}

if (!customElements.get('thrilltech-content-card')) {
    customElements.define('thrilltech-content-card', ThrilltechContentCard);
}
