import {css, html, TemplateResult} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import {msg, str} from '@lit/localize';
import {LitElement} from 'lit';
import {button} from "../styles/button";
import {ThrilltechError} from "../interfaces/ThrilltechError";

@customElement('error-display')
export class ErrorDisplay extends LitElement {
    static override styles = [
        button,
        css`
            .thrilltech-error {
                padding: 24px 12px 24px 12px;
            }

            .thrilltech-error-icon {
                text-align: center;
            }

            .thrilltech-error-title {
                margin: 8px 0 8px 0;
                font-size: 14px;
                font-weight: 700;
                line-height: 19.6px;
                text-align: center;
            }
            .thrilltech-error-description {
                margin: 0px 0 24px 0;
                font-size: 12px;
                font-weight: 400;
                line-height: 16.8px;
                text-align: center;
            }
            .buttons {
                width: 100%;
                display: flex;
                gap: 16px;
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }

            .buttons .primary,
            .buttons .secondary {
                min-width: 207px;
                height: 38px;
            }
        `
    ];

    @property({type: Object, attribute: "thrilltech-error"})
    thrilltechError: ThrilltechError = {
        title: '',
        message: '',
        retry: false,
        tryReload: false,
    };

    override connectedCallback(): void {
        super.connectedCallback();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
    }

    override render(): TemplateResult<1> {
        return html`
            <div class="thrilltech-error">
                <div class="thrilltech-error-icon">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5152 3.0775C11.9205 2.3075 13.0766 2.3075 13.4818 3.0775L22.3809 19.9863C22.7466 20.6811 22.2151 21.4988 21.3976 21.4988H3.59943C2.78201 21.4988 2.25042 20.6811 2.61612 19.9863L11.5152 3.0775Z" fill="#FFE682"/>
                        <path d="M13.4907 8.99896C13.4907 8.44671 13.043 7.99902 12.4907 7.99902C11.9384 7.99902 11.4907 8.44671 11.4907 8.99896V14.9986C11.4907 15.5508 11.9384 15.9985 12.4907 15.9985C13.043 15.9985 13.4907 15.5508 13.4907 14.9986V8.99896Z" fill="#7D3500"/>
                        <path d="M12.4907 19.4983C13.043 19.4983 13.4907 19.0506 13.4907 18.4984C13.4907 17.9461 13.043 17.4984 12.4907 17.4984C11.9384 17.4984 11.4907 17.9461 11.4907 18.4984C11.4907 19.0506 11.9384 19.4983 12.4907 19.4983Z" fill="#7D3500"/>
                    </svg>
                </div>
                <div class="thrilltech-error-title">${this.thrilltechError.title}</div>
                <div class="thrilltech-error-description">${this.thrilltechError.message}</div>
                <div class="buttons">
                    ${this.getErrorButton()}
                </div>
            </div>
        `;
    }

    private getErrorButton() {
        if (this.thrilltechError.tryReload) {
            return html`
                <button class="primary"
                    href="javascript:void(0)"
                    @click="${this.errorReload}">
                    ${msg(str`Reload Page`)}
                </button>
                <button class="secondary"
                    href="javascript:void(0)"
                    @click="${this.errorClose}">
                    ${msg(str`Close`)}
                </button>
            `;
        } else if (this.thrilltechError.retry) {
            return html`
                <button class="primary"
                    href="javascript:void(0)"
                    @click="${this.errorRetry}">
                    ${msg(str`Try again`)}
                </button>
            `;
        } else {
            return html`
                <button class="secondary"
                    href="javascript:void(0)"
                    @click="${this.errorClose}">
                    ${msg(str`Close`)}
                </button>
            `;
        }
    }

    private errorReload(): void {
        this.dispatchEvent(new CustomEvent('error-reload', {bubbles: true}));
    }

    private errorRetry(): void {
        this.dispatchEvent(new CustomEvent('error-retry', {bubbles: true}));
    }

    private errorClose(): void {
        this.dispatchEvent(new CustomEvent('error-close', {bubbles: true}));
    }
}

if (!customElements.get('error-display')) {
    customElements.define('error-display', ErrorDisplay);
}